.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  padding-top: 30px;
  padding-bottom: 55px;
  text-align: center;
}

.nav-bar {
  padding-top: 24px;
  display: inline-block;
  text-align: center;
}

.no-focus.focus,
.no-focus:focus {
  outline: 0;
  box-shadow: none !important;
}

.timeline-margin {
  margin-top: -40px;
}

.col {
  padding-right: 40px;
  padding-left: 40px;
}

.footer {
  margin-left: -60px;
}

@media only screen and (max-width: 600px) {
  .footer {
    margin-left: 0px;
  }
}

.black-links a {
  color: black;
  text-decoration: underline;
}
